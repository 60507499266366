import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as Arrow } from "../svg/curve-arrow.svg";

export default function HeadingAndText({
  id,
  headingLine1,
  headingLine2,
  fontColor,
  text,
}) {
  return (
    <section id={id}>
      <Row>
        <Col xs={12} lg={6}>
          <h2 className="marker blue text-center mb-lg-0">
            Why do our laws <br /> need to change?
          </h2>
          <div className="heading-arrow">
            <Arrow />
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <p>{text}</p>
        </Col>
      </Row>
    </section>
  );
}
