import React from "react";
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import Container from "react-bootstrap/Container";
import Section from "./components/Section";
import Footer from "./components/Footer";
import Cta from "./components/Cta";
import Icons from "./components/Icons";
import FactCarousel from "./components/FactCarousel";
import HeaderImage from "./images/headeroption4.jpg";
import HeadingAndText from "./components/HeadingAndText";
import LivingWithHiv from "./components/LivingWithHiv";
import Committees from "./components/Committees";
import { ReactComponent as Underline } from "./svg/underline2.svg";

function App() {
  let introContent = {
    id: "about",
    cols: [
      {
        classes: "",
        size: 12,
        content: [
          <h2 className="text-center marker red">
            Change the Law. <br /> End the Stigma.
          </h2>,
          <p>
            The Missouri HIV Justice Coalition, a project of{" "}
            <a href="https://empowermissouri.org/">Empower Missouri</a> is a
            coalition of groups and individuals working to modernize outdated
            and stigmatizing HIV criminal laws in the state of Missouri. Here's
            how we're working to end the stigma and improve the lives of those
            living with HIV:
          </p>,
          <Icons
            icons={[
              {
                name: "book",
                header: "Legislation",
                text:
                  "Know the law, be the change Missouri Hiv Laws, advocacy, policy, what you need to know",
              },
              {
                name: "lightbulb",
                header: "Education",
                text:
                  "Knowledge is power. Inform and educate, become a member, be the light be the change",
              },
              {
                name: "heart",
                header: "Resources",
                text:
                  "How to get involved, who’s your legislator, connect with a committee.",
              },
            ]}
          />,
        ],
      },
    ],
  };

  let changingLawsContent = {
    id: "changingLaws",
    headingLine1: "Why do our laws",
    headingLine2: "need to Change?",
    fontColor: "blue",
    text:
      "Missouri law criminalizes sex between consenting adults when one is living with HIV. The onus is put on people living with HIV to prove that they disclosed their status prior to sex. Even acts that don't transmit the disease such as spitting are treated as felonies. The law ignores advances in medical science and disincentivizes HIV testing; states with HIV criminal laws do not have lower transmission rates. The laws need to change.",
  };

  let carouselItems = [
    {
      title: "Fact Check: Up to Fifteen Years in Prison",
      content:
        "Missouri punishes the inability to prove disclosure prior to sex and needle sharing even when no transmission occurs with up to fifteen years in prison.",
    },
    {
      title: "Fact Check: HIV can't be transmitted by saliva, urine, or feces",
      content:
        "Spitting can't transmit HIV and biting would have to be severe and bloody, yet these actions can be treated as felony crimes in Missouri.",
    },
    {
      title: "Fact Check: HIV is not a death sentence",
      content:
        "HIV is a chronic manageable health condition. With access to affordable treatment a person diagnosed today can expect a near normal lifespan.",
    },
  ];
  return (
    <div className="App">
      <Navigation />
      <Header image={HeaderImage} />
      <Container className="main-content">
        <div className="pt-5 px-1 px-lg-3">
          <h1 className="marker red text-center">
            MO HIV Justice Coalition <br /> <Underline />
          </h1>
        </div>
        <Section data={introContent} />
        <HeadingAndText {...changingLawsContent} />
        <FactCarousel items={carouselItems} />
        <Committees />
        <LivingWithHiv />
      </Container>
      <Cta />
      <Footer />
    </div>
  );
}

export default App;
