import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase/app";
import "firebase/analytics";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

var firebaseConfig = {
  apiKey: "AIzaSyDk25PRfi9AJ0eg-B2cQPDDpB61Q_xGy7c",
  authDomain: "mohivjustice.firebaseapp.com",
  projectId: "mohivjustice",
  storageBucket: "mohivjustice.appspot.com",
  messagingSenderId: "251273358454",
  appId: "1:251273358454:web:99c65396afc2a9fca60357",
  measurementId: "G-GP4FHHXY3D",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
