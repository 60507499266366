import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EmpowerLogo from "../images/empower-logo.png";
import EtafLogo from "../images/etaf-logo.png";

export default function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
            <h3 className="marker">Contact</h3>
            <a href="mailto:mohivjustice@empowermissouri.org">
              <i className="far fa-envelope fa-2x m-2"></i>
            </a>
            <a href="tel:5736342901">
              <i className="fas fa-phone fa-2x m-2"></i>
            </a>
          </Col>
          <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
            <h3 className="marker">Connect</h3>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/MOHIVJustice/"
            >
              <i className="fab fa-facebook fa-2x m-2"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/mohivjustice"
            >
              <i className="fab fa-twitter fa-2x m-2"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/mohivjustice/"
            >
              <i className="fab fa-instagram fa-2x m-2"></i>
            </a>
          </Col>
          <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
            <h3 className="marker">Support</h3>
            <a
              className="partner-link m-2"
              target="_blank"
              rel="noreferrer"
              href="https://empowermissouri.org/"
            >
              <img src={EmpowerLogo} alt="Empower Missouri" />
            </a>
            <a
              className="partner-link m-2"
              target="_blank"
              rel="noreferrer"
              href="https://elizabethtayloraidsfoundation.org/"
            >
              <img
                className="rounded-circle"
                alt="Elizabeth Taylor Aids Foundation"
                src={EtafLogo}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
