import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../images/logo.png";

export default function Navigation() {
  return (
    <div>
      <Navbar bg="blue" variant="dark" expand="md" className="shadow">
        <Navbar.Brand href="/">
          <img src={Logo} alt="MO HIV Justice Coalition" />
        </Navbar.Brand>
        <div className="d-md-flex order-md-1 d-none">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/MOHIVJustice/"
          >
            <i className="fab fa-facebook fa-2x m-1"></i>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/mohivjustice"
          >
            <i className="fab fa-twitter fa-2x m-1"></i>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/mohivjustice/"
            className="mr-2"
          >
            <i className="fab fa-instagram fa-2x m-1"></i>
          </a>
        </div>
        <a href="#cta" className="btn btn-primary order-md-2">
          Join Us
        </a>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Item>
              <Nav.Link href="#changingLaws">Our Work</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#committees">About Us</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#learn-more">Learn More</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex d-md-none mb-1 mx-auto">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/MOHIVJustice/"
              >
                <i className="fab fa-facebook fa-2x m-1"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/mohivjustice"
              >
                <i className="fab fa-twitter fa-2x m-1"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/mohivjustice/"
                className="mr-2"
              >
                <i className="fab fa-instagram fa-2x m-1"></i>
              </a>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
