import React from "react";

export default function Header({ image }) {
  return (
    <header
      className="mb-1"
      style={{ backgroundImage: `url(${image})` }}
    ></header>
  );
}
