import React from "react";

export default function Cta() {
  return (
    <section id="cta" className="bg-blue">
      <div className="m-auto text-center">
        <div className="cta-heading">
          <h2 className="marker white">Join Us</h2>
        </div>
        <p className="white">
          Use the form below to let us know that you’re interested in joining
          our HIV Justice Coalition.
        </p>
      </div>
      <div
        className="ngp-form"
        data-form-url="https://secure.everyaction.com/v1/Forms/7H1SQVHn4kWOXdix0EtUvQ2"
        data-fastaction-endpoint="https://fastaction.ngpvan.com"
        data-inline-errors="true"
        data-fastaction-nologin="true"
        data-databag-endpoint="https://profile.ngpvan.com"
        data-databag="everybody"
        data-mobile-autofocus="false"
      ></div>
    </section>
  );
}
