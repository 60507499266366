import React from "react";
import Carousel from "react-bootstrap/Carousel";

export default function FactCarousel({ items }) {
  return (
    <section>
      <Carousel
        prevIcon={<i className="fas fa-angle-left fa-2x"></i>}
        nextIcon={<i className="fas fa-angle-right fa-2x"></i>}
        indicators={false}
      >
        {items.map((item, idx) => {
          return (
            <Carousel.Item key={idx}>
              <div className="fact-check shadow mx-md-5">
                <h2 className="marker gold">{item.title}</h2>
                <p>{item.content}</p>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
}
