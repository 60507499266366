import React, { useState } from "react";
import { ReactComponent as Book } from "../svg/book.svg";
import { ReactComponent as Heart } from "../svg/heart.svg";
import { ReactComponent as Lightbulb } from "../svg/lightbulb.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function Icons({ icons }) {
  const svgs = {
    book: <Book />,
    lightbulb: <Lightbulb />,
    heart: <Heart />,
  };
  return (
    <div>
      <div className="d-flex flex-column flex-md-row justify-content-around">
        {icons.map((icon, idx) => {
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => {
                return <Tooltip {...props}>{icon.text}</Tooltip>;
              }}
            >
              <div className="icon-container my-2" key={idx}>
                {svgs[icon.name]}
                <h5 className="marker red w-fit-content m-auto text-center">
                  {icon.header}
                </h5>
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
}
