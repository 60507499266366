import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as ChatBubble } from "../svg/chat-bubble.svg";
import { ReactComponent as HeadLightbulb } from "../svg/head-lightbulb.svg";
import { ReactComponent as ClipboardCheckmark } from "../svg/clipboard-checkmark.svg";

export default function Committees() {
  return (
    <section id="committees">
      <Row className="mb-4">
        <Col xs={12}>
          <div>
            <h2 className="marker red text-center">Our Committees</h2>
            <p>
              Want to get involved? We have several commitees within our
              Coalition each with a different purpose but all working towards
              the common goal of modernizing our HIV laws in Missouri. Read
              about each committee and <a href="#cta">fill out the form</a> to
              join us. Each committee has a chair who helps coordinate, guide,
              and facilitate activities.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} lg={4}>
          <div className="commitee-icon mb-3 mb-lg-0">
            <ChatBubble />
          </div>
        </Col>
        <Col xc={12} lg={8}>
          <h3 className="marker red text-center text-lg-left mb-2">
            Media &amp; Messaging
          </h3>
          <p>
            <em>
              Create and execute a communications strategy based on our policy
              goals.
            </em>
          </p>
          <ul>
            <li>
              create content / help manage the Coalition’s website and social
              media accounts
            </li>
            <li>
              create, edit, and approve guest columns, letters to the editor,
              and other published works that represent the coalition
            </li>
            <li>
              develop &amp; provide messaging training to coalition members /
              volunteers
            </li>
            <li>
              prepare talking points for coalition members to prepare them for
              interviews news articles, radio, podcasts, etc.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} lg={4}>
          <div className="commitee-icon mb-3 mb-lg-0">
            <HeadLightbulb />
          </div>
        </Col>
        <Col xc={12} lg={8}>
          <h3 className="marker red text-center text-lg-left mb-2">
            Education &amp; Membership
          </h3>
          <p>
            <em>
              Lead statewide awareness-building &amp; recruitment activities.
            </em>
          </p>
          <ul>
            <li>
              plan, host, and lead panels, presentations, &amp; workshops on HIV
              criminalization and other HIV- related policy priorities
            </li>
            <li>plan &amp; host coalition recruitment activities</li>
            <li>
              story-bank personal accounts of how HIV criminal statutes &amp;
              other relevant policies impact people living with HIV
            </li>
            <li>lead new members through the coalition onboarding process</li>
            <li>plan member engagement activities aimed at member retention</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} lg={4}>
          <div className="commitee-icon mb-3 mb-lg-0">
            <ClipboardCheckmark />
          </div>
        </Col>
        <Col xc={12} lg={8}>
          <h3 className="marker red text-center text-lg-left mb-2">
            Legislative &amp; Advocacy
          </h3>
          <p>
            <em>Spearhead our engagement in the legislative process.</em>
          </p>
          <ul>
            <li>
              collaborate with bill sponsors; support development of bill and
              amendment language gather information on key legislators
            </li>
            <li>
              take the lead on relationship development &amp; communication with
              legislators
            </li>
            <li>
              monitor legislation, budget, and administrative policies that fall
              under the purview of the coalition’s mission
            </li>
            <li>create &amp; contribute to policy briefs</li>
            <li>
              help plan advocacy days in Jefferson City for coalition members
              and supporters.
            </li>
          </ul>
        </Col>
      </Row>
    </section>
  );
}
