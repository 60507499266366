import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Section({ data }) {
  return (
    <section id={data.id}>
      <Row>
        {data.cols.map((col, idx) => {
          return (
            <Col xs={12} lg={col.size} className={col.classes} key={idx}>
              {col.content.map((content, idx) => {
                return <div key={idx}>{content}</div>;
              })}
            </Col>
          );
        })}
      </Row>
    </section>
  );
}
