import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";

export default function LivingWithHiv() {
  let images = [
    "/carousel/image11.jpeg",
    "/carousel/image12.jpeg",
    "/carousel/image13.jpeg",
    "/carousel/image14.jpeg",
    "/carousel/image18.jpeg",
  ];
  return (
    <section id="learn-more">
      <Row>
        <Col xs={12}>
          <div className="text-center mb-4">
            <h2 className="marker blue">Coalition Voices</h2>
            <p>
              See what some of the many people working towards modernization
              have to say about HIV Justice in Missouri.
            </p>
          </div>
          <Carousel
            prevIcon={<i className="fas fa-angle-left fa-2x"></i>}
            nextIcon={<i className="fas fa-angle-right fa-2x"></i>}
            indicators={false}
          >
            {images.map((image, idx) => {
              return (
                <Carousel.Item className="px-md-5" key={idx}>
                  <img
                    src={image}
                    alt=""
                    className="d-flex w-100 mb-3 shadow"
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
      </Row>
    </section>
  );
}
